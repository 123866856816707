/* Handles map related CSS */

.map-info-window {
    position: relative;
    bottom: 100px;
    left: -45px;
    width: 180px;
    background-color: white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    z-index: 100;
  }