@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./overflow.css');
@import url('./map.css');
@import url('./pages.css');
@import url('./toast.css');

html, body {
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  -webkit-tap-highlight-color: transparent; /* prevents the blue highlight when clicking on buttons/links */
}

section {
  min-height: 90vh;
  padding-bottom: 3rem;
}

/* This will be used as background for dropdown to achieve closure outside of component */
.dropdown-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

input[disabled]{
  background: #dddddd;
  cursor: not-allowed;
}

@media (max-width: 640px) {
    .dropdown-action {
    position: relative;
   }
}

.tooltip-list .multi-line {
   text-align: left !important;
}
