.styled-overflow::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.styled-overflow::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.styled-overflow::-webkit-scrollbar-thumb
{
	background-color: #c2c2c2;
	border: 2px solid #c2c2c2;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
